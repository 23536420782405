// Dependencies
import React from "react";
import { Link } from "gatsby";
// Components
import Layout from "../components/Layout";
import { CommentIcon, RetweetIcon, LikeIcon } from "../components/Icons";

const StyelguidePage = () => (
  <Layout title="Styleguide">

		<div className="padx2" style={{ maxWidth: "60rem" }}>
			<div className="p3 outerx4">
				<h1>Empathise and engage</h1>
				<p>
					If there’s one thing we’ve learned makes better buildings, it’s empathy. Architecture never happens in isolation—and simply by being interested and engaged, we’ve found it’s possible to open up the conversations that connect a scheme to its clients and community.
				</p>
			</div>

			<div className="p3 outerx4">
				<p>
					Unity Hub at Craig Park <br />
					Tight site and budgetary guidelines <br />
					Extending the park into the new buildings
				</p>
			</div>

			<div className="outerx4">
				<h5>{"24 Nov 2020 | > nla.london.com"}</h5>
				<h1>NLA Publishes Our Urban Farm Proposal in Zero Carbon.</h1>
			</div>

			<div className="outerx4">
				<h5>{"24 Nov 2020 | "} <CommentIcon /><RetweetIcon /><LikeIcon /></h5>
				<h1>NLA Publishes Our Urban Farm Proposal in Zero Carbon.</h1>
			</div>

			<div className="p2 outerx4">
				<h5>{"Sector"}</h5>
				<ul>
					<li>Housing</li>
					<li>Healthcare</li>
					<li>Arts and Public</li>
					<li>Education</li>
					<li>Learning</li>
					<li>Community</li>
					<li>Heritage and Restoration</li>
				</ul>
			</div>

			<div className="p4 outerx4">
				<h5>{"Works"}</h5>
				<ul>
					<li>Alfreton Park SEN School</li>
					<li>Brooklands Park</li>
					<li>Brooklands Park</li>
					<li>Croydon High Pavilion</li>
					<li>Derriford Care Centre</li>
					<li>Elmview Court</li>
					<li>Ethos</li>
				</ul>
			</div>

			<div className="outerx4">
				<blockquote>
					‘Good design makes housing much more than just the sum of the bricks and mortar it’s made from. Well-designed housing inspires people to take ownership and care better for it: it lasts longer and so people stay longer and their sense of belonging increases.’ <cite className="h5">Marianne Christiansen</cite>
				</blockquote>
			</div>

			<div className="outerx4">
				<div className="grid-4">
					<Link to="/" className="project-thumbnail">
						<div className="thumbnail"></div>
						<h4>Project Title</h4>
					</Link>
					<Link to="/" className="project-thumbnail">
						<div className="thumbnail"></div>
						<h4>Project Title</h4>
					</Link>
					<Link to="/" className="project-thumbnail">
						<div className="thumbnail"></div>
						<h4>Project Title</h4>
					</Link>
					<Link to="/" className="project-thumbnail">
						<div className="thumbnail"></div>
						<h4>Project Title</h4>
					</Link>
				</div>
			</div>

			<div className="outerx4">
				<p>
					Morland Gardens is a large, mixed-use development in Stonebridge, close to Harlesden town centre in north-west London. Along with the general need for social and affordable housing, our clients, Brent’s regeneration team, had identified a specific need for homes that would provide a safe haven for those recently arrived in the borough. Their ambition was not only to deliver these homes, but to complement them with learning space and affordable work units that residents could.
				</p>
				<p>
					We had been having conversations with Brent for some time, looking at different models for combining uses, but Morland Gardens presented a real opportunity to develop these further. We knew that the project would not just be about architecture; it would be about demonstrating empathy for the future residents and users of the scheme, and building a design out of the many conversations – formal and off-the-cuff – that we had with the council’s education officers and regeneration specialists.
				</p>
				<p>
					High-quality adult education is already offered on site at the Stonebridge Centre by Brent Start. We looked at a number of options to retain the locally-listed Victorian villa which is integrated with the centre buildings, but the council’s preferred option was for a full regeneration to create more homes. The resulting scheme uses the sloping site to integrate the different functions of the Stonebridge Centre with the new housing and workspace. It takes the form of a perimeter block around a central courtyard, modelled to protect the inner space from the busy road alongside, but to also to take full advantage of the opportunities offered by the significant street frontage.
				</p>
			</div>

			<div className="p3 outerx4">
				<h5>1 of 5</h5>
				<p>
					<span className="c-passive">As well as the generous soft landscaping to the main courtyard, there is a smaller, more intimate outside space for the sheltered housing element of the scheme.</span> <span className="c-social">Rooflights into the learning centre below are incorporated into the landscape above as play structures.</span>
				</p>
			</div>

			<div className="p3 outerx4">
				<h5>2 of 5</h5>
				<p>
					<span className="c-constraints">As well as the generous soft landscaping to the main courtyard, there is a smaller, more intimate outside space for the sheltered housing element of the scheme.</span> 
				</p>
			</div>
		</div>

  </Layout>
)

export default StyelguidePage
